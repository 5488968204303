<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'

import ButtonAnswersTemplate from '@/components/shared/base-components/ButtonAnswersTemplate'

const questionText = 'Are you currently employed?'
const ANSWERS = [
  'Currently Employed',
  'Student',
  'Stay at Home',
  'Retired',
  'Unemployed',
  'Disabled',
  'Military'
]

const stepName = 'EmploymentStatus'

export default {
  name: stepName,

  components: {
    ButtonAnswersTemplate
  },

  emits: ['next-step'],

  setup(_, {emit}) {
    const store = useStore()

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const setAnswer = (userInput) => {
      const emptySpace = /[ ]/g
      const answer = userInput.replace(emptySpace, '_').toLowerCase()

      store.commit('SET_FIELD', {field: 'employment_status', value: answer})
      emit('next-step', stepName)
    }

    return {
      questionText,
      ANSWERS,
      setAnswer
    }
  }
}
</script>

<template>
  <div>
    <button-answers-template
      :button-action=setAnswer
      :answers="ANSWERS"
      :question-text="questionText"
      :grid-button-style="true"
    />
  </div>
</template>

<style scoped lang="scss">

</style>